export function kebabToCapitalCase(str: string) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export function capitalize(sentence: string) {
  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
}

export function formatToShortenNumber(num: number) {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(0).replace(/\.0$/, "") + "m"
  }
  if (num >= 1_000) {
    return (num / 1_000).toFixed(0).replace(/\.0$/, "") + "k"
  }
  return num.toString()
}

// getCroppedImg.ts
export function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number }
): Promise<{ blob: Blob; url: string }> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageSrc
    image.crossOrigin = "anonymous" // Needed for cross-origin images

    image.onload = () => {
      const canvas = document.createElement("canvas")
      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height
      const ctx = canvas.getContext("2d")

      if (!ctx) {
        reject(new Error("Failed to get canvas context"))
        return
      }

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      )

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"))
            return
          }
          const url = URL.createObjectURL(blob)
          resolve({ blob, url })
        },
        "image/jpeg",
        1
      )
    }

    image.onerror = () => {
      reject(new Error("Failed to load image"))
    }
  })
}
