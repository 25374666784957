"use client";

import { cn } from "@/lib/utils";
import React from "react";
import { Card, CardContent } from "../ui/card";
import Image from "next/image";
import Link from "next/link";
import { Skeleton } from "../ui/skeleton";
import useUIStore from "@/store/useUIStore";
import { DEMO_BOOK } from "@/data";
const TOP_BOOKS_WEEK = [{
  id: 1,
  title: "The Illness Lesson",
  rank: 1,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 2,
  title: "Grown",
  rank: 2,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 3,
  title: "The Valley and The flood",
  rank: 3,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 4,
  title: "A taste for love",
  rank: 4,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 5,
  title: "What's not to love",
  rank: 5,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 6,
  title: "The Illness Lesson",
  rank: 6,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 7,
  title: "Grown",
  rank: 7,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 8,
  title: "The Valley and The flood",
  rank: 8,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 9,
  title: "A taste for love",
  rank: 9,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}, {
  id: 10,
  title: "What's not to love",
  rank: 10,
  url: `/book/${DEMO_BOOK.slug}`,
  cover: "/assets/book-cover.png",
  rating: 4.3,
  totalRatings: 5700
}];
const Top10Books = ({
  className
}: {
  className?: string;
}) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <div className={cn("m-0", className)} data-sentry-component="Top10Books" data-sentry-source-file="Top10Books.tsx">
      <div className="sm:mb-3 sm:border-b border-[#98c6f4]/20 pb-6">
        {isSkeletonLoading ? <Skeleton className="h-12 w-[250px]" /> : <h3 className="text-xl sm:text-2xl font-bold sm:font-semibold leading-8 text-white">
            Top 10 Books This Week
          </h3>}
      </div>
      <Card className="shadow-none" data-sentry-element="Card" data-sentry-source-file="Top10Books.tsx">
        <CardContent className="p-0 max-sm:space-y-5" data-sentry-element="CardContent" data-sentry-source-file="Top10Books.tsx">
          {TOP_BOOKS_WEEK.map(book => <TopBookCard key={book.id} {...book} />)}
        </CardContent>
      </Card>
    </div>;
};
export default Top10Books;
interface TopBookProps {
  className?: string;
  id: number;
  cover: string;
  title: string;
  url: string;
  rank: number;
  totalRatings: number;
  rating: number;
}
const TopBookCard = ({
  className,
  cover,
  rank,
  title,
  url
}: TopBookProps) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <Card className={cn("rounded-[24px] sm:px-5 sm:py-4 shadow-none hover:cursor-pointer hover:bg-white/10", className)} data-sentry-element="Card" data-sentry-component="TopBookCard" data-sentry-source-file="Top10Books.tsx">
      {isSkeletonLoading ? <Skeleton className="h-[84px] w-full rounded-[24px] bg-white/10" /> : <CardContent className="flex items-center gap-4 sm:gap-5 p-0">
          <span className="max-sm:hidden border-b-2 border-b-[#94A8F8] text-lg text-white">
            {rank.toString().padStart(2, "0")}
          </span>

          <Link href={url} className="h-[120px] w-20 sm:h-[84px] sm:w-[56px] flex-shrink-0 overflow-hidden rounded-[4px] sm:rounded-[8px]">
            <Image src={cover} alt={title} className="h-full w-full rounded-[4px] sm:rounded-[8px] object-cover object-center" width={56} height={84} />
          </Link>
          <div className="flex flex-col gap-0.5">
            <div className="sm:hidden size-[38px] bg-[#232533] flex items-center justify-center rounded-[50%] mb-0.5">
              <span className=" text-base font-medium text-white">
                {rank.toString().padStart(2, "0")}
              </span>
            </div>
            <Link href={url}>
              <h4 className="sm:my-[9px] text-base sm:text-lg font-bold sm:font-normal text-white">
                {title}
              </h4>
            </Link>

            <Link href={"#"}>
              <p className="sm:hidden text-base font-normal text-[#adadad]">
                Diana Urban
              </p>
            </Link>
          </div>
        </CardContent>}
    </Card>;
};