"use client";

import * as React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { formatToShortenNumber, kebabToCapitalCase } from "@/utils";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { FaChevronRight } from "react-icons/fa";
import { Book } from "@/types";
import { Badge } from "../ui/badge";
import { Skeleton } from "../ui/skeleton";
import useUIStore from "@/store/useUIStore";
import BookCover from "./BookCover";
interface BookCarouselProps {
  className?: string;
  title: string;
  url: string;
  books: (Book & {
    category?: string;
    shelf?: string;
  })[];
  isShelf?: boolean;
  hideTabs?: boolean;
  hideMore?: boolean;
}
const BookCarousel = React.memo(({
  className,
  title,
  url,
  books,
  isShelf,
  hideTabs,
  hideMore
}: BookCarouselProps) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  const tabs = React.useMemo(() => isShelf ? Array.from(new Set(books.map(book => book.shelf))) as string[] : Array.from(new Set(books.map(book => book.category))), [books, isShelf]);
  const getFilteredBooks = (tab: string) => {
    const tempBooks: (Book | null)[] = books.filter(book => isShelf ? book.shelf === tab : book.category === tab);
    const length = tempBooks.length;
    while (tempBooks.length < 7) {
      tempBooks.push(null);
    }
    return {
      books: tempBooks,
      length
    };
  };
  return <div className={cn("", className)}>
        {isSkeletonLoading ? <Skeleton className="h-8 w-[300px] mb-5" /> : <div className={cn("mb-5 flex items-center gap-5", hideTabs && "mb-6")}>
            <Link href={url} className="text-xl sm:text-2xl font-semibold text-white">
              {title}
            </Link>
            {!hideMore && <Link href="/list/popular" className="font-medium text-white underline-offset-[5px] hover:underline flex items-center gap-1">
                <span className="">See more</span>
                <FaChevronRight size={12} />
              </Link>}
          </div>}
        <Carousel opts={{
      align: "start",
      slidesToScroll: "auto",
      watchDrag: false
    }} className="max-sm:hidden"
    // className='w-[107.15%]'
    >
          <Tabs defaultValue={tabs[0]}>
            {!hideTabs ? <TabsList className="bg-transparent p-0">
                {tabs.map(tab => <>
                    {isSkeletonLoading ? <Skeleton className="h-6 mr-2 w-[100px]" /> : <TabsTrigger className={cn("mb-6 p-0 pr-8 text-base font-medium text-white data-[state=active]:bg-transparent data-[state=active]:text-[#94A8F8]")} key={tab} value={tab}>
                        {kebabToCapitalCase(tab)}{" "}
                        <Badge className="ml-1 rounded-[107px] bg-[#202020] px-2 py-0 text-sm font-normal text-inherit transition-none hover:bg-[#202020]">
                          {formatToShortenNumber(getFilteredBooks(tab).length)}
                        </Badge>
                      </TabsTrigger>}
                  </>)}
              </TabsList> : null}

            {tabs.map(tab => <TabsContent key={tab} value={tab} className="mt-0 ">
                <CarouselContent className="-ml-5 max-sm:hidden">
                  {getFilteredBooks(tab).books.map((book, index) => <CarouselItem key={index} className="pl-5 max-sm:hidden  md:basis-1/2 lg:basis-[14.25%]">
                      <div className="aspect-[1/1.5] h-full w-full overflow-hidden rounded-[8px]">
                        {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : book ? <BookCover book={book} width={170} height={255} className="h-full w-full rounded-[8px] object-cover object-center" /> : <FallbackImage />}
                      </div>
                    </CarouselItem>)}
                </CarouselContent>
              </TabsContent>)}
          </Tabs>
        </Carousel>

        {tabs.map(tab => <div className="grid grid-cols-3 sm:hidden gap-3" key={tab}>
            {getFilteredBooks(tab).books.slice(0, 9).map((book, index) => <div key={index} className="aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px] sm:rounded-[8px]">
                  {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : book ? <BookCover book={book} width={170} height={255} className="h-full w-full rounded-[8px] object-cover object-center" /> : <FallbackImage />}
                </div>)}
          </div>)}
      </div>;
});
BookCarousel.displayName = "BookCarousel";
export default BookCarousel;
const FallbackImage = () => {
  return <div className="flex h-full w-full items-center justify-center rounded-[8px] bg-[#272834] object-cover object-center" data-sentry-component="FallbackImage" data-sentry-source-file="BookCarousel.tsx">
      <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="BookCarousel.tsx">
        <path d="M29 54.375C43.0142 54.375 54.375 43.0142 54.375 29C54.375 14.9858 43.0142 3.625 29 3.625C14.9858 3.625 3.625 14.9858 3.625 29C3.625 43.0142 14.9858 54.375 29 54.375Z" stroke="white" strokeOpacity="0.2" strokeWidth="3.625" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="BookCarousel.tsx" />
        <path d="M18.125 29H39.875" stroke="white" strokeOpacity="0.2" strokeWidth="3.625" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="BookCarousel.tsx" />
        <path d="M29 18.125V39.875" stroke="white" strokeOpacity="0.2" strokeWidth="3.625" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="BookCarousel.tsx" />
      </svg>
    </div>;
};