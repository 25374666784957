"use client";

import React from "react";
import { motion } from "framer-motion";
// import { useLikeResource } from "@/hooks/useLikeResource"
import { HiOutlineHeart } from "react-icons/hi2";

// interface LikeButtonProps {
//   // resourceId: string
//   // resourceType: "review" | "reviewComment"
//   // initialLiked: boolean
//   // initialLikesCount?: number
// }

const heartVariants = {
  initial: {
    scale: 1
  },
  liked: {
    scale: [1, 1.2, 1],
    transition: {
      duration: 0.3
    }
  },
  unliked: {
    scale: [1, 0.9, 1],
    transition: {
      duration: 0.3
    }
  }
};
const LikeButton = ({
  // resourceId,
  // resourceType,
  // initialLiked,
  // initialLikesCount = 0,
}) => {
  const [showParticles, setShowParticles] = React.useState(false);

  // const { liked, likesCount, toggleLike } = useLikeResource({
  //   resourceType,
  //   resourceId,
  //   initialLiked,
  //   initialLikesCount,
  // })

  const [liked, setLiked] = React.useState(false);
  const handleClick = async () => {
    setLiked(!liked);
    // await toggleLike()
    if (!liked) {
      setShowParticles(true);
      setTimeout(() => setShowParticles(false), 500);
    }
  };
  return <button onClick={handleClick} className="relative flex group items-center focus:outline-none p-1 rounded-full hover:bg-red-400 transition-colors" aria-pressed={liked} aria-label={liked ? "Unlike" : "Like"} data-sentry-component="LikeButton" data-sentry-source-file="LikeButton.tsx">
      <motion.div animate={liked ? "liked" : "unliked"} variants={heartVariants} initial="initial" className="flex items-center justify-center" data-sentry-element="unknown" data-sentry-source-file="LikeButton.tsx">
        <HiOutlineHeart className={`h-5 w-5 ${liked ? "fill-red-500 text-red-500 transition-colors duration-200" : "text-white group-hover:text-red-200 transition-colors duration-200"}`} data-sentry-element="HiOutlineHeart" data-sentry-source-file="LikeButton.tsx" />
      </motion.div>
      {showParticles && <LikeParticles />}
      {/* <span className="ml-2 text-sm text-gray-700">{likesCount}</span> */}
    </button>;
};
export default React.memo(LikeButton);
const particles = Array.from({
  length: 5
});
const LikeParticles: React.FC = () => <div className="absolute inset-0 flex items-center justify-center pointer-events-none" data-sentry-component="LikeParticles" data-sentry-source-file="LikeButton.tsx">
    {particles.map((_, index) => {
    const angle = index / particles.length * 2 * Math.PI;
    const radius = 20;
    return <motion.div key={index} className="bg-red-500 size-40 rounded-full" style={{
      width: 30,
      height: 30
    }} initial={{
      opacity: 1,
      scale: 0
    }} animate={{
      opacity: 0,
      scale: 1,
      x: Math.cos(angle) * radius,
      y: Math.sin(angle) * radius
    }} transition={{
      duration: 0.5,
      ease: "easeOut"
    }} />;
  })}
  </div>;