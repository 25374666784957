import React from "react";
import { Button } from "@/components/ui/button";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { cn } from "@/lib/utils";
import StatusShelvesDropdown from "./StatusShelvesDropdown";
const AddToShelfButton = ({
  className
}: {
  className?: string;
}) => {
  return <div className={cn("flex h-[46px] rounded-[9px] bg-[#5069c3]", className)} data-sentry-component="AddToShelfButton" data-sentry-source-file="AddToShelfButton.tsx">
      <Button type="button" className="h-full rounded-l-[8px] flex-1 rounded-r-none bg-inherit py-3 pl-4 pr-[21px] text-base font-medium sm:font-normal leading-[22px] text-white" data-sentry-element="Button" data-sentry-source-file="AddToShelfButton.tsx">
        <HiOutlineBookOpen size={18} className="text-white" data-sentry-element="HiOutlineBookOpen" data-sentry-source-file="AddToShelfButton.tsx" />
        <span style={{
        filter: "drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25))"
      }} className="ml-2 text-base text-white">
          Want to Read
        </span>
      </Button>
      <StatusShelvesDropdown trigger={<Button type="button" className="flex w-10  h-full items-center rounded-l-none rounded-r-[8px] border-l border-l-white/40 bg-inherit px-4 py-3">
            <svg width="8" height="22" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2117_800)">
                <path d="M0 9.16667L4 13.75L8 9.16667H0Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2117_800">
                  <rect width="22" height="8" fill="white" transform="matrix(0 -1 1 0 0 22)" />
                </clipPath>
              </defs>
            </svg>
          </Button>} data-sentry-element="StatusShelvesDropdown" data-sentry-source-file="AddToShelfButton.tsx" />
      {/* <Button
        type="button"
        className="flex h-full items-center rounded-l-none rounded-r-[8px] border-l border-l-white/40 bg-inherit px-4 py-3"
       >
        <svg
          width="8"
          height="22"
          viewBox="0 0 8 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2117_800)">
            <path d="M0 9.16667L4 13.75L8 9.16667H0Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_2117_800">
              <rect
                width="22"
                height="8"
                fill="white"
                transform="matrix(0 -1 1 0 0 22)"
              />
            </clipPath>
          </defs>
        </svg>
       </Button> */}
    </div>;
};
export default AddToShelfButton;