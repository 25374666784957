import Link from "next/link";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { formatToShortenNumber } from "@/utils";
import { HiMiniStar } from "react-icons/hi2";
import React from "react";
import { Card, CardContent } from "../ui/card";
import { cn } from "@/lib/utils";
import { Skeleton } from "../ui/skeleton";
import ReviewActionsMenu from "../reviews/ReviewActionsMenu";
import AddToShelfButton from "./AddToShelfButton";
import ReadMoreText from "./ReadMoreText";
import BookCover from "./BookCover";
import { DEMO_BOOK } from "@/data";
import LikeButton from "../reviews/LikeButton";
interface ReviewProps {
  id: number;
  className?: string;
  cover: string;
  title: string;
  type: string;
  content: string;
  url: string;
  likes: number;
  totalReviews: number;
  rating: number;
  author_name: string;
}
const TrendingReviewsThisWeekCard = ({
  id,
  className,
  title,
  content,
  url,
  likes,
  totalReviews,
  rating,
  author_name,
  imageClassName,
  hideAuthor = false
}: ReviewProps & {
  hideAuthor?: boolean;
  imageClassName?: string;
}) => {
  // const isSkeletonLoading = useUIStore((state) => state.isSkeletonLoading)

  return <Card className={cn(" rounded-none max-sm:rounded-[8px] max-sm:bg-[#232533] pt-5 pb-6 sm:py-6 sm:pr-7 max-sm:px-4 shadow-none sm:first:pt-0", className)} data-sentry-element="Card" data-sentry-component="TrendingReviewsThisWeekCard" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
      <CardContent className="flex max-sm:flex-col gap-4 p-0" data-sentry-element="CardContent" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
        <div className="flex max-sm:gap-4 sm:gap-8 sm:px-0  sm:py-5 max-sm:items-center ">
          <div className={cn("sm:h-[144px] sm:w-[96px] h-[120px] w-20 flex-shrink-0 overflow-hidden rounded-[4px] sm:rounded-[8px]", imageClassName)}>
            {false ? <Skeleton className="h-full w-full" /> : <BookCover book={DEMO_BOOK} className="h-full rounded-[4px] sm:rounded-[8px] object-cover object-center" width={96} height={144} />}
          </div>

          <div className="flex flex-col">
            {/* <span
             className={cn(
              "text-lg text-[#94A8F8]",
              type === "Deep Dive" && "text-[#6BC589]",
              type === "funny" && "text-[#FFA432]"
             )}
             >
             # {capitalize(type)}
             </span> */}
            {false ? <Skeleton className="h-5 w-[300px]" /> : <Link href={url} className=" underline-offset-2 hover:underline">
                {" "}
                <h4 className="mb-2.5 mt-1 text-xl sm:text-2xl font-extrabold max-sm:leading-6 sm:font-semibold text-white">
                  {title}
                </h4>
              </Link>}

            {false ? <Skeleton className="h-4 w-[280px] mt-2" /> : <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2 sm:gap-[13px] text-white">
                  {!hideAuthor && <div className="flex items-center justify-center gap-2">
                      <Avatar className="size-[26px] sm:h-8 sm:w-8 sm:border-2 border-white">
                        <AvatarImage width={32} height={32} src={`https://i.pravatar.cc/150?img=${id}`} alt="author" />
                        <AvatarFallback>U</AvatarFallback>
                      </Avatar>
                      <span className="sm:text-base text-sm font-semibold">
                        {author_name}
                      </span>
                    </div>}
                  <div className="flex items-center gap-0.5 sm:gap-1">
                    <span className="text-sm sm:text-base font-medium">
                      {rating}
                    </span>
                    <HiMiniStar color="#FFA432" className="sm:size-[18px]" />
                  </div>
                  <span className="-ml-0.5 max-sm:hidden text-[15px] leading-[18px] font-light">
                    2 days ago
                  </span>
                </div>

                <div className="sm:hidden">
                  <AddToShelfButton />
                </div>
              </div>}

            <div className="max-sm:hidden">
              {false ? <Skeleton className="mt-2.5 h-[174px] w-[728px]" /> : <ReadMoreText text={content} mobileLength={640} desktopLength={640} className="mb-4 mt-2.5 whitespace-pre-line text-sm font-normal text-[#f4f4f4] sm:text-[#E6E6FA]" />}
              <div className="flex items-center gap-5 text-sm font-semibold text-white sm:text-[#e6e6fa]/90">
                <div className="flex items-center gap-1">
                  {/* <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                   >
                    <path
                      d="M8.24909 14.6528L8.24837 14.6521C6.30729 12.8919 4.7364 11.4645 3.64494 10.1292C2.55898 8.80059 2 7.62437 2 6.375C2 4.33424 3.59055 2.75 5.625 2.75C6.77885 2.75 7.89456 3.28973 8.62058 4.13564L9 4.57771L9.37942 4.13564C10.1054 3.28973 11.2212 2.75 12.375 2.75C14.4095 2.75 16 4.33424 16 6.375C16 7.62437 15.441 8.80059 14.3551 10.1292C13.2636 11.4645 11.6927 12.8919 9.75163 14.6521L9.75091 14.6528L9 15.3363L8.24909 14.6528Z"
                      stroke="white"
                    />
                   </svg> */}

                  <LikeButton data-sentry-element="LikeButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

                  <span>{formatToShortenNumber(likes)}</span>
                </div>

                <Link href="/book/lord-harry/reviews/sam" className="flex items-center gap-1" data-sentry-element="Link" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                    <path d="M8.99956 15.75C10.4718 15.7497 11.9035 15.268 13.0766 14.3785C14.2496 13.489 15.0997 12.2403 15.4973 10.8228C15.8948 9.40531 15.8181 7.89669 15.2787 6.52687C14.7393 5.15704 13.7668 4.00113 12.5095 3.23527C11.2522 2.46941 9.77897 2.1356 8.31434 2.28472C6.84971 2.43384 5.474 3.05771 4.39684 4.06125C3.31968 5.0648 2.60014 6.39298 2.34788 7.84341C2.09563 9.29384 2.32448 10.787 2.99956 12.0953L2.24956 15.75L5.90431 15C6.83131 15.4793 7.88431 15.75 8.99956 15.75Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                    <path d="M5.625 9H5.6325V9.0075H5.625V9ZM9 9H9.0075V9.0075H9V9ZM12.375 9H12.3825V9.0075H12.375V9Z" stroke="white" strokeWidth="2" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                  </svg>

                  <span>{formatToShortenNumber(totalReviews)}</span>
                </Link>

                <ReviewActionsMenu shareUrl="https://test.com" reviewAuthorName="testauthor" reporterUsername="testreporter" data-sentry-element="ReviewActionsMenu" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </div>
              <AddToShelfButton className="max-w-fit mt-4" data-sentry-element="AddToShelfButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
            </div>
          </div>
        </div>

        <div className="sm:hidden">
          {false ? <Skeleton className="mt-2.5 h-[174px] w-[728px]" /> : <ReadMoreText text={content} mobileLength={640} desktopLength={640} className="mb-4  whitespace-pre-line text-sm font-normal text-[#f4f4f4] sm:text-[#E6E6FA]" />}
          <div className="flex items-center gap-5 text-sm font-semibold text-white sm:text-[#e6e6fa]/90">
            <div className="flex items-center gap-1">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                <path d="M8.24909 14.6528L8.24837 14.6521C6.30729 12.8919 4.7364 11.4645 3.64494 10.1292C2.55898 8.80059 2 7.62437 2 6.375C2 4.33424 3.59055 2.75 5.625 2.75C6.77885 2.75 7.89456 3.28973 8.62058 4.13564L9 4.57771L9.37942 4.13564C10.1054 3.28973 11.2212 2.75 12.375 2.75C14.4095 2.75 16 4.33424 16 6.375C16 7.62437 15.441 8.80059 14.3551 10.1292C13.2636 11.4645 11.6927 12.8919 9.75163 14.6521L9.75091 14.6528L9 15.3363L8.24909 14.6528Z" stroke="white" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </svg>

              <span>{formatToShortenNumber(likes)}</span>
            </div>

            <div className="flex items-center gap-1">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                <path d="M8.99956 15.75C10.4718 15.7497 11.9035 15.268 13.0766 14.3785C14.2496 13.489 15.0997 12.2403 15.4973 10.8228C15.8948 9.40531 15.8181 7.89669 15.2787 6.52687C14.7393 5.15704 13.7668 4.00113 12.5095 3.23527C11.2522 2.46941 9.77897 2.1356 8.31434 2.28472C6.84971 2.43384 5.474 3.05771 4.39684 4.06125C3.31968 5.0648 2.60014 6.39298 2.34788 7.84341C2.09563 9.29384 2.32448 10.787 2.99956 12.0953L2.24956 15.75L5.90431 15C6.83131 15.4793 7.88431 15.75 8.99956 15.75Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                <path d="M5.625 9H5.6325V9.0075H5.625V9ZM9 9H9.0075V9.0075H9V9ZM12.375 9H12.3825V9.0075H12.375V9Z" stroke="white" strokeWidth="2" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </svg>
              <span>{formatToShortenNumber(totalReviews)}</span>
            </div>

            <ReviewActionsMenu shareUrl="https://test.com" reviewAuthorName="testauthor" reporterUsername="testreporter" data-sentry-element="ReviewActionsMenu" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
          </div>
        </div>
      </CardContent>
    </Card>;
};
export default TrendingReviewsThisWeekCard;