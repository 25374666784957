"use client";

import { cn } from "@/lib/utils";
import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import TrendingReviewsThisWeekCard from "../shared/TrendingReviewsThisWeekCard";
import { Skeleton } from "../ui/skeleton";
import useUIStore from "@/store/useUIStore";
import { DEMO_BOOK } from "@/data";
export const REVIEWS = [{
  id: 1,
  cover: `/assets/review-book-cover.png`,
  type: "personal",
  title: "Iron Flame",
  content: `Iron Flame by Rebecca Yarros is a mesmerizing sequel that surpasses its predecessor in every way. The richly detailed world-building and intricate plot keep you hooked from start to finish. Protagonist Violet's journey is filled with intense battles, unexpected twists, and profound character growth.\n
Yarros masterfully blends action, romance , and political intrigue, creating a story that's both thrilling and emotionally resonant. The supporting characters are equally compelling, each adding depth and complexity to the narrative. Iron Flame is a must-read for fans of fantasy, offering a gripping adventure that leaves you eagerly anticipating the next installment.`,
  url: `/book/${DEMO_BOOK.slug}`,
  likes: 456,
  totalReviews: 456,
  rating: 8,
  author_name: "Miranda Hedge"
}, {
  id: 3,
  cover: `/assets/review-book-cover.png`,
  type: "funny",
  title: "One Dark Window",
  content: "One Dark Window is like a spooky fairy tale on a roller coaster, full of twists and turns that’ll keep you guessing and laughing! With a cast of quirky characters and unexpected surprises, it’s the perfect mix of eerie and entertaining! 😂",
  url: `/book/${DEMO_BOOK.slug}`,
  likes: 456,
  totalReviews: 456,
  rating: 8,
  author_name: "Miranda Hedge"
}, {
  id: 2,
  cover: `/assets/review-book-cover.png`,
  type: "Deep Dive",
  title: "The Crimson Moth",
  content: `The Crimson Moth by [Author Name] is an ambitious novel that explores the dark and intricate web of espionage and intrigue. Set against the backdrop of a war-torn Europe️, the story follows protagonist Eleanor "Elle" Morton, an enigmatic spy known by her codename, the Crimson Moth.\n
The novel’s greatest strength lies in its atmospheric setting and rich historical detail. The author masterfully recreates the tension and paranoia of wartime, immersing readers in a world where every shadow holds a threat. Elle is a compelling character, her complexity and moral ambiguity adding depth to the narrative. Her journey through betrayal, loss, and resilience is gripping, and her relationships with other characters are nuanced and well-developed️.
\nOverall, The Crimson Moth is a captivating read for fans of historical fiction and espionage, offering a vivid portrayal of a perilous era, despite its occasional narrative hiccups.\n
The novel’s greatest strength lies in its atmospheric setting and rich historical detail. The author masterfully recreates the tension and paranoia of wartime, immersing readers in a world where every shadow holds a threat. Elle is a compelling character, her complexity and moral ambiguity adding depth to the narrative. Her journey through betrayal, loss, and resilience is gripping, and her relationships with other characters are nuanced and well-developed️.
\nOverall, The Crimson Moth is a captivating read for fans of historical fiction and espionage, offering a vivid portrayal of a perilous era, despite its occasional narrative hiccups.\n
The novel’s greatest strength lies in its atmospheric setting and rich historical detail. The author masterfully recreates the tension and paranoia of wartime, immersing readers in a world where every shadow holds a threat. Elle is a compelling character, her complexity and moral ambiguity adding depth to the narrative. Her journey through betrayal, loss, and resilience is gripping, and her relationships with other characters are nuanced and well-developed️.
\nOverall, The Crimson Moth is a captivating read for fans of historical fiction and espionage, offering a vivid portrayal of a perilous era, despite its occasional narrative hiccups.\n
The novel’s greatest strength lies in its atmospheric setting and rich historical detail. The author masterfully recreates the tension and paranoia of wartime, immersing readers in a world where every shadow holds a threat. Elle is a compelling character, her complexity and moral ambiguity adding depth to the narrative. Her journey through betrayal, loss, and resilience is gripping, and her relationships with other characters are nuanced and well-developed️.
\nOverall, The Crimson Moth is a captivating read for fans of historical fiction and espionage, offering a vivid portrayal of a perilous era, despite its occasional narrative hiccups.\n
The novel’s greatest strength lies in its atmospheric setting and rich historical detail. The author masterfully recreates the tension and paranoia of wartime, immersing readers in a world where every shadow holds a threat. Elle is a compelling character, her complexity and moral ambiguity adding depth to the narrative. Her journey through betrayal, loss, and resilience is gripping, and her relationships with other characters are nuanced and well-developed️.
\nOverall, The Crimson Moth is a captivating read for fans of historical fiction and espionage, offering a vivid portrayal of a perilous era, despite its occasional narrative hiccups.`,
  url: `/book/${DEMO_BOOK.slug}`,
  likes: 456000,
  totalReviews: 546,
  rating: 8,
  author_name: "Miranda Hedge"
}];
const TrendingReviewsThisWeek = ({
  className
}: {
  className?: string;
}) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <div className={cn("  ", className)} data-sentry-component="TrendingReviewsThisWeek" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
      <div className="sm:mb-3 sm:border-b border-[#98c6f4]/20 pb-6">
        {isSkeletonLoading ? <Skeleton className="h-12 w-[400px]" /> : <h3 className="text-xl font-bold sm:text-2xl sm:font-semibold leading-8 text-white">
            Trending Reviews This Week
          </h3>}
      </div>
      <Card className="shadow-none " data-sentry-element="Card" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
        <CardContent className="sm:divide-y max-sm:space-y-4  divide-[#98c6f4]/20 p-0 sm:pr-5" data-sentry-element="CardContent" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
          {REVIEWS.map(review => <TrendingReviewsThisWeekCard key={review.id} {...review} />)}
        </CardContent>
      </Card>
    </div>;
};
export default TrendingReviewsThisWeek;