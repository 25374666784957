"use client";

import Image from "next/image";
import React from "react";
import Link from "next/link";
import useUIStore from "@/store/useUIStore";
import { Skeleton } from "../ui/skeleton";
const Hero = () => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <section className="relative overflow-hidden" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <div className="relative flex max-h-screen min-h-[500px] sm:min-h-[699px] w-full overflow-hidden">
        {isSkeletonLoading ? null : <Image src="/assets/home/featured-image.png" width={2000} height={999} className="w-full object-cover" alt="featured cover" />}
        <div className="absolute inset-0 bg-black/25 p-0"></div>
        <div className="absolute w-full left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 sm:-translate-y-1/2 flex-col items-center justify-center gap-14 sm:gap-10 text-white">
          <div className="text-center max-sm:flex max-sm:flex-col max-sm:gap-2 text-[28px] sm:text-[44px] font-semibold sm:font-bold  leading-5 sm:leading-snug -tracking-[1%]">
            {isSkeletonLoading ? <Skeleton className="h-6 sm:h-14 w-[338px] sm:w-[604px]" /> : <span>Track books you&apos;ve read.</span>}
            <br />
            {isSkeletonLoading ? <Skeleton className="h-6 sm:h-14 -mt-10 w-[338px] sm:w-[604px]" /> : <span>Save your future reads.</span>}
            <br />
            {isSkeletonLoading ? <Skeleton className="h-6 sm:h-12 -mt-10 w-[338px] sm:w-[604px]" /> : <span>Discover new favorites.</span>}
          </div>
          {isSkeletonLoading ? <Skeleton className="h-12 w-[200px]" /> : <Link className="rounded-[8px] bg-[#5069ce] max-sm:w-fit text-base font-bold sm:text-[18px] px-16 sm:px-8 py-4 sm:py-3.5 sm:font-semibold text-white" href="/signup">
              Get started — it&apos;s free!
            </Link>}
        </div>
      </div>
      <div className="max-sm:hidden absolute left-1/2 -mt-56 h-[288px] w-[120%] -translate-x-1/2 bg-[#10111e]" style={{
      filter: "blur(60px)"
    }}></div>
    </section>;
};
export default Hero;